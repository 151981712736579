
$ug-primary-color: #bccf00;
$ug-dark-color: #002b33;
$ug-danger-color: #d93c38;
$ug-warning-color: #ffc807;
$ug-info-color: #14919f;
$ug-success-color: #1e7b34;

$ug-font-color: $ug-dark-color;
$ug-light-color: #fff;

$ug-font-stack: Arial, sans-serif;
$ug-lightblue-color:#f5f9fc;
$ug-hover-color:#26BCA1;
$ug-emailBox-color:#C3BCB5;
$ug-text-color:#153335;
$ug-green-color:#60AC00;
