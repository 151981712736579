@import "~bootstrap/scss/bootstrap.scss";
@import "./assets/scss/ug-variables";

.navbar {
  align-items: center;
  color: white;
  background-color: $ug-lightblue-color;
  width: 100%;

  img {
    height: 4rem;
    padding-left: 10px;
  }

  h3 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16pt;
    font-family: $ug-font-stack;
    font-weight: bold;
    color: $ug-hover-color;
  }
}

@media (max-width: 1024px) {
  .navbar {
    flex-direction: column;
    text-align: center;

    img {
      height: 2rem;
      margin-bottom: 10px;
    }

    h3 {
      position: static;
      transform: none;
    }
  }
}

.navbarBrand {
  margin-right: auto;
}

.footer {
  position: fixed;
  bottom: 0;
  height: 2rem;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.7rem;

  background-color: $ug-lightblue-color;
  text-transform: uppercase;

  span {
    margin-left: 1.4rem;
  }

  a {
    text-decoration: none;
    color: $ug-text-color;
  }

  a:hover {
    color: $ug-hover-color
  }
}

.roundBorder {
  padding: 1rem;
  border: 0.1rem solid $ug-emailBox-color;
  border-radius: 36px;
  background-color: $ug-emailBox-color;
}

.customContainer {
  h2, h4, p, span, text {
    font-family: $ug-font-stack;
    color: $ug-text-color;
  }

  h2 {
    font-size: 14pt;
    font-weight: bold;
  }

  h4 {
    font-size: 12pt;
    font-weight: bold;
  }

  p, span, text {
    font-size: 12pt;
  }
}

.emaillabel:focus {
  border-color: $ug-green-color;
}

.emailBlock {
  font-family: $ug-font-stack;
  color: $ug-text-color;
  font-size: 12pt;
}

.submitBtn {
  background-color: $ug-green-color !important;
  color: black;
  font-weight: bold;
  border-radius: 40px;
  width: 200px;
}