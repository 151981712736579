@import "./ug-variables";

$primary: $ug-primary-color;
$dark: $ug-dark-color;
$secondary: $ug-dark-color;
$danger: $ug-danger-color;
$warning: $ug-warning-color;
$info: $ug-info-color;
$success: $ug-primary-color;
$component-active-color: $ug-dark-color;

$navbar-dark-active-color: $ug-primary-color;
$navbar-dark-hover-color: $ug-light-color;

.list-group-item-action { //bootstrap doesn't style item-actions by default
  cursor: pointer;
}

//include after overwriting bootstrap variables
@import "../../../node_modules/bootstrap/scss/bootstrap";
